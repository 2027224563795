<template>
  <div class="modal fade " id="franchiseeInformationUpdateModal" tabindex="-1" role="dialog"
       aria-labelledby="franchiseeInformationUpdateModal"
       aria-hidden="true">
    <div class="modal-dialog  modal-lg modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header bg-primary">
          <h1 class="modal-title white">Update Franchisee Information</h1>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <i class="bx bx-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <h5>Commission Information</h5>
              <div class="row">
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label>Royalty Percentage *</label>
                    <input v-model="putFranchiseeData.royalty_percentage" min="0.00" step="0.001" type="number"
                           class="form-control">
                    <div class="text-danger" v-if="errors.franchisee.royaltyPercentage">
                      {{ errors.franchisee.royaltyPercentage }}
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label>Operation Percentage *</label>
                    <input v-model="putFranchiseeData.operation_percentage" min="0.00" step="0.001" type="number" class="form-control "
                           placeholder="Enter Operation Percentage">
                    <div class="text-danger" v-if="errors.franchisee.operationPercentage">
                      {{ errors.franchisee.operationPercentage }}
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label>Marketing Percentage *</label>
                    <input v-model="putFranchiseeData.marketing_percentage" min="0.00" step="0.001" type="number" class="form-control "
                           placeholder="Enter Marketing Percentage.">
                    <div class="text-danger" v-if="errors.franchisee.marketingPercentage">
                      {{ errors.franchisee.marketingPercentage }}
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label>Technician Limit *</label>
                    <input v-model="putFranchiseeData.technician_limit" type="number" class="form-control "
                           placeholder="Enter Technician Limit">
                    <div class="text-danger" v-if="errors.franchisee.technicianLimit">
                      {{ errors.franchisee.technicianLimit }}
                    </div>
                  </div>
                </div>
              </div>
              <h5>ABN Information</h5>
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label>ABN *</label>
                    <input v-model="putFranchiseeData.abn" type="text" class="form-control "
                           placeholder="Enter ABN.">
                    <div class="text-danger" v-if="errors.franchisee.abn">
                      {{ errors.franchisee.abn }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer border-0 pt-0">
          <button type="button" class="btn btn-primary" @click="updateFranchiseeInformation">
            <i class="bx bx-x d-block d-sm-none"></i>
            <span class="d-none d-sm-block">Save Changes</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// core packages
import {mapActions} from "vuex";
// mixins
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";

export default {
  name: "FranchiseeInformationUpdateModal",
  props: ['franchisee'],
  emits: ['updateFranchiseeData'],
  mixins: [ShowToastMessage, Loader],
  data() {
    return {
      id: this.franchisee.id,
      putFranchiseeData: {
        royalty_percentage: '',
        operation_percentage: '',
        marketing_percentage: '',
        technician_limit: '',
        abn: '',
      },
      errors: {
        franchisee: {
          royaltyPercentage: '',
          operationPercentage: '',
          marketingPercentage: '',
          technicianLimit: '',
          abn: '',
        },
      },
    };
  },
  watch: {
    franchisee(franchisee) {
      this.id = franchisee.id;
      this.putFranchiseeData = {
        royalty_percentage: franchisee.royalty_percentage,
        operation_percentage: franchisee.operation_percentage,
        marketing_percentage: franchisee.marketing_percentage,
        technician_limit: franchisee.technician_limit,
        abn: franchisee.abn,
      };
    },
  },
  methods: {
    ...mapActions({
      putFranchisee: 'appFranchisees/putFranchisee',
    }),

    async emitUpdateFranchiseeData(updateFranchiseeData) {
      this.$emit('updateFranchiseeData', updateFranchiseeData);
    },

    async updateFranchiseeInformation() {
      let dataObj = {
        id: this.id,
        data: this.putFranchiseeData,
      };
      await this.loader(true);
      await this.putFranchisee(dataObj).then(async (response) => {
        await this.loader(false);
        if (response.status === 200) {
          document.querySelector('[data-target="#franchiseeInformationUpdateModal"]').click();

          let toastObj = {
            type: 'success',
            message: 'Franchisee Information Updated Successfully.',
          };
          await this.showToastMessage(toastObj);
          await this.emitUpdateFranchiseeData(this.putFranchiseeData);
        }

        this.errors.franchisee.royaltyPercentage = response.errors && response.errors.royalty_percentage ? response.errors.royalty_percentage[0] : '';
        this.errors.franchisee.operationPercentage = response.errors && response.errors.operation_percentage ? response.errors.operation_percentage[0] : '';
        this.errors.franchisee.marketingPercentage = response.errors && response.errors.marketing_percentage ? response.errors.marketing_percentage[0] : '';
        this.errors.franchisee.technicianLimit = response.errors && response.errors.technician_limit ? response.errors.technician_limit[0] : '';
        this.errors.franchisee.abn = response.errors && response.errors.abn ? response.errors.abn[0] : '';

        if (response.message) {
          this.showToastMessage(response);
        }
      });
    }
  },
}
</script>